var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [(_vm.getCity.logo && _vm.getCity.logo.length > 0)?_c('b-avatar',{attrs:{"src":_vm.getCity.logo,"variant":"light-primary","size":"90px","rounded":""}}):_c('b-avatar',{attrs:{"variant":"light-primary","text":_vm.avatarText(_vm.getCity.nameRu),"size":"90px","rounded":""}})]},proxy:true}])},[_c('div',{staticClass:"d-flex mb-1 align-items-center"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getCity.nameRu)+" ")])]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":function($event){$event.preventDefault();return _vm.deleteCity.apply(null, arguments)}}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Удалить")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"TrashIcon"}})],1)],1)]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Название Ru","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название Ru","label-for":"full-name-ru"}},[_c('b-form-input',{attrs:{"id":"full-name-ru","state":errors.length > 0 ? false : null},model:{value:(_vm.getCity.nameRu),callback:function ($$v) {_vm.$set(_vm.getCity, "nameRu", $$v)},expression:"getCity.nameRu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Название Uz","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название Uz","label-for":"full-name-uz"}},[_c('b-form-input',{attrs:{"id":"full-name-uz","state":errors.length > 0 ? false : null},model:{value:(_vm.getCity.nameUz),callback:function ($$v) {_vm.$set(_vm.getCity, "nameUz", $$v)},expression:"getCity.nameUz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Название En","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название En","label-for":"full-name-en"}},[_c('b-form-input',{attrs:{"id":"full-name-en","state":errors.length > 0 ? false : null},model:{value:(_vm.getCity.nameEn),callback:function ($$v) {_vm.$set(_vm.getCity, "nameEn", $$v)},expression:"getCity.nameEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Код","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Код","label-for":"city-code"}},[_c('b-form-input',{attrs:{"id":"city-code","state":errors.length > 0 ? false : null},model:{value:(_vm.getCity.code),callback:function ($$v) {_vm.$set(_vm.getCity, "code", $$v)},expression:"getCity.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v(" Сохранить ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }