<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          v-if="getCity.logo && getCity.logo.length > 0"
          :src="getCity.logo"
          :variant="`light-primary`"
          size="90px"
          rounded
        />
        <b-avatar
          v-else
          :variant="`light-primary`"
          :text="avatarText(getCity.nameRu)"
          size="90px"
          rounded
        />
      </template>
      <div class="d-flex mb-1 align-items-center">
        <h4 class="mb-0">
          {{ getCity.nameRu }}
        </h4>
      </div>
      <div class="d-flex flex-wrap">
        <b-button @click.prevent="deleteCity" variant="outline-danger">
          <span class="d-none d-sm-inline">Удалить</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>

    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- Field: Full Name -->

          <b-col cols="12" md="3">
            <validation-provider
              #default="{ errors }"
              name="Название Ru"
              rules="required"
            >
              <b-form-group label="Название Ru" label-for="full-name-ru">
                <b-form-input
                  id="full-name-ru"
                  :state="errors.length > 0 ? false : null"
                  v-model="getCity.nameRu"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Full Name -->
          <b-col cols="12" md="3">
            <validation-provider
              #default="{ errors }"
              name="Название Uz"
              rules="required"
            >
              <b-form-group label="Название Uz" label-for="full-name-uz">
                <b-form-input
                  id="full-name-uz"
                  :state="errors.length > 0 ? false : null"
                  v-model="getCity.nameUz"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Full Name -->
          <b-col cols="12" md="3">
            <validation-provider
              #default="{ errors }"
              name="Название En"
              rules="required"
            >
              <b-form-group label="Название En" label-for="full-name-en">
                <b-form-input
                  id="full-name-en"
                  :state="errors.length > 0 ? false : null"
                  v-model="getCity.nameEn"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Full Name -->
          <b-col cols="12" md="3">
            <validation-provider
              #default="{ errors }"
              name="Код"
              rules="required"
            >
              <b-form-group label="Код" label-for="city-code">
                <b-form-input
                  id="city-code"
                  :state="errors.length > 0 ? false : null"
                  v-model="getCity.code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12">
            <!-- Action Buttons -->
            <b-button
              @click.prevent="handleSubmit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            >
              Сохранить
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { avatarText } from "@core/utils/filter";
import { mapGetters } from "vuex";
import axiosIns from "@/libs/axios.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";

export default {
  components: {
    ToastificationContent,
    BBadge,
    BFormTextarea,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {},
  computed: {
    ...mapGetters(["getCity"]),
    statusVariant() {
      const statusVariants = {
        /* eslint-disable key-spacing */
        ACTIVE: {
          color: "light-success",
          text: "Активный",
        },
        DELETED: {
          color: "light-danger",
          text: "Удаленный",
        },
        BLOCKED: {
          color: "light-warning",
          text: "Заблокированный",
        },
        SUSPENDED: {
          color: "light-secondary",
          text: "Приостановленный",
        },
        /* eslint-enable key-spacing */
      };

      return (status) => statusVariants[status];
    },
  },
  setup() {
    return {
      avatarText,
    };
  },
  data() {
    return {
      required,
      email,
      password,
      confirmed,

      file: null,
      fileUrl: "",
      categories: null,
      logo: null,
      statusOptions: ["ACTIVE", "DELETED", "BLOCKED", "SUSPENDED"],
    };
  },
  methods: {
    async handleFileUpload(event) {
      this.file = event.target.files[0];
      const formData = new FormData();
      formData.append("logo", this.file);
      const response = await axiosIns.post("image/upload/category", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.getCity.logoUrl = response.data.data.imageUrl;
    },
    changeCategoryList() {},
    async handleSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axiosIns
            .put("v1/city/edit", {
              id: this.getCity.id,
              nameRu: this.getCity.nameRu,
              nameUz: this.getCity.nameUz,
              nameEn: this.getCity.nameEn,
              code: this.getCity.code,
            })
            .then((response) => {
              if (response.status == 200) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Успешно`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Город был успешно изменен!`,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
              }
            })
            .catch((error) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Ошибка`,
                    icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                    variant: "danger",
                    text: error.response.data.errorMessage,
                  },
                },
                {
                  position: "top-right",
                }
              );
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            })
            .finally(() => {
              this.$router.push({ name: "cities" });
            });
        }
      });
    },
    async deleteCity() {
      this.$bvModal
        .msgBoxConfirm("Вы уверены что хотите удалить?", {
          cancelVariant: "outline-secondary",
          okVariant: "danger",
          okTitle: "Удалить",
          cancelTitle: "Отмена",
        })
        .then((value) => {
          if (value === true) {
            axiosIns
              .delete("v1/city/delete", {
                params: {
                  cityId: this.$router.currentRoute.params.id,
                },
              })
              .then((response) => {
                if (response.status == 200) {
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: `Успешно`,
                        icon: "CheckCircleIcon",
                        variant: "success",
                        text: `Город был успешно удален!`,
                      },
                    },
                    {
                      position: "top-right",
                    }
                  );
                  this.getData();
                }
              })
              .catch((error) => {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Ошибка`,
                      icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                      variant: "danger",
                      text: error.response.data.errorMessage,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
                if (error.response) {
                  // Request made and server responded
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log("Error", error.message);
                }
              })
              .finally(() => {
                this.$router.push({ name: "cities" });
              });
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
